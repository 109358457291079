import React, { useState, useEffect } from 'react'
import { graphql, navigate } from 'gatsby'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'

export const LivePage = ({
  data: {
    site: {
      siteMetadata: { title },
    },
  },
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState('en')
  const subtitle = `Come join me while I stream free interesting contents!`

  useEffect(() => {
    if (selectedLanguage === 'it') {
      navigate('/it/live/')
    }
  }, [selectedLanguage])

  return (
    <Layout
      location='/live'
      title={title}
      defaultLang='US'
      setSelectedLanguage={setSelectedLanguage}
      isIndex={true}
      subtitle={subtitle}
    >
      <SEO
        title={title}
        keywords={['blog', 'tags', 'page', 'twitch', 'mixer']}
      />
      Live page is working!
    </Layout>
  )
}

export default LivePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
